import { formatDistanceToNow } from "date-fns";
import { shallowEqual, useSelector } from "react-redux";

import {
  getCameraModuleById,
  getCapabilityClassById
} from "~/selectors/CameraSelector";
import {
  selectAttrReportedByDeviceId,
  selectDeviceStatusByDeviceId
} from "~/selectors/DeviceAttrSelector";

/**
 * Returns the `connected` and `scanning` statuses of a camera by deviceId. The
 * scanning state depends on the connected state, and the values are queried from
 * reportedProperties, which means they will be updated when SignalR sends an
 * update message.
 *
 * Example use:
 * const { isConnected, isScanning } = useCameraStatus("apalis-imx6-123456789");
 *
 * @param {*} deviceId
 *
 * @returns {Object}
 */
export const useCameraStatus = deviceId => {
  const cameraModule = useSelector(getCameraModuleById(deviceId));
  const capabilityClass = useSelector(getCapabilityClassById(deviceId));
  const reportedAttributes = useSelector(
    selectAttrReportedByDeviceId(deviceId),
    shallowEqual
  );

  const { connectionState } = useSelector(
    state => selectDeviceStatusByDeviceId(state, deviceId),
    shallowEqual
  );

  const isConnected = connectionState === "Connected";
  const isScanning =
    isConnected && (reportedAttributes?.scanning ?? cameraModule?.scanning);

  const getUptime = () => {
    if (!isConnected || !cameraModule?.lastStartTimeUtc) return 0;

    const startTime = new Date(cameraModule?.lastStartTimeUtc);
    const uptime = formatDistanceToNow(startTime);
    const isOverAYear =
      uptime.includes("years") || uptime.includes("over 1 year");

    if (isOverAYear) return "N/A";

    return uptime;
  };

  return {
    connectionState,
    isConnected,
    isScanning,
    uptime: getUptime(),
    capabilityClass
  };
};
